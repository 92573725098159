// --------------------------------------------------
// Module: Maps
// --------------------------------------------------

#main-map {
  height: 100%;
  width: 100%;

  z-index: 1;
}

.layers-menu {
  position: absolute;
  background-color: white;
  right: 0;
  z-index: 5;
}

// Geolocate
.find-me {
  position: absolute;
  top: 108px;
  left: 40px;
  z-index: 9;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  overflow: hidden;
  padding: 2px 2px 2px 0;

  button {
    float: left;
    height: 30px;
    position: relative;
    border-width: 0;

    &:first-child {
      z-index: 2;
      font-weight: bold;
      line-height: 30px;
      color: #1a2a2a;
      background-color: #fff;
      padding: 0 0.75em;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      box-shadow: -2px -2px 0 rgba(0,0,0,0.1), -2px 2px 0 rgba(0,0,0,0.1);

      &:hover {
        background-color: #F2F2F2;
        color: #1a2a2a;
      }
    }

    &:last-child {
      z-index: 1;
      font-size: 20px;
      line-height: 20px;
      color: $warning-color;
      background-color: #fafafa;
      padding: 0 0.6em 0.2em 0.5em;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      border-left: 1px solid $medium-gray;
      box-shadow: 0 0 0 2px rgba(0,0,0,0.1);

      &:hover {
        color: darken($warning-color,10%);
      }
    }
  }
}

// Drawing
.draw-tools {
  position: absolute;
  bottom: 70px;
  left: 10px;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
  overflow: hidden;

  label {
    float: left;
    background-color: $white;
    font-size: 0.75rem;
    line-height: 1.5;
    height: 30px;
    padding: rem-calc(6) rem-calc(10) rem-calc(5) rem-calc(8);
    color: $dark-gray;

    .fa {
      margin-right: rem-calc(1);
      margin-left: rem-calc(3);
      color: $body-font-color;
    }
  }
}

.draw-tool {
  @include button(false, $white, #f2f2f2, $primary-color, solid);
  float: left;
  white-space: nowrap;
  font-size: rem-calc(12);
  padding: 0 rem-calc(10);
  margin: 0;
  border-left: 1px solid #ddd;
  border-right-width: 0;
  font-size: rem-calc(19);
  height: 30px;
  outline: 0;

  &.active {
    background-color: $a11y-yellow;
  }

}

.draw-tool--line {}
.draw-tool--polygon {
  .icon {
    font-size: rem-calc(12);
    margin-top: -0.1rem;
    margin-right: 0;
  }
}
.draw-tool--clear {
  color: $lu-red;
}

.draw-measurement {
  position: absolute;
  bottom: 105px;
  left: 10px;
  z-index: 10;
  background-color: $white;
  font-size: rem-calc(12);
  padding: rem-calc(6) rem-calc(10) rem-calc(5) rem-calc(8);
  box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
  border-radius: 4px;
}

.draw-measurement-menu-button {
  @include button(false, $white, #f2f2f2, $primary-color, solid);
  font-size: 1rem;
  margin: 0 rem-calc(-4) 0 0;
  padding: rem-calc(6);
  position: relative;
}

.draw-measurement-menu {
  position: absolute;
  top: 100%;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, 0);
  font-size: 0;
  background-color: $white;
  box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
  margin-top: rem-calc(-4);
  border-radius: 4px;

  button {
    margin: rem-calc(4);

    &:last-child {
      margin-left: 0;
    }
  }
}

.print-controls {
  position: absolute;
  top: 10px;
  left: 50px;
  z-index: 1;
  overflow: visible;
}

// Map Tooltip
.map-tooltip {
  position: absolute;
  background-color: $white;
  z-index: 1000;
  padding: rem-calc(2) rem-calc(6);
  color: $dark-gray;
  font-size: rem-calc(11);
  box-shadow: 0 0 0 2px rgba(0,0,0,0.1);
  border-radius: 2px;
}

.loading-spinner {
  opacity: 0.5;
  display: block;
  margin: 2rem auto 0 auto;
  margin-top: 2rem;
  pointer-events: none;
}

.map-loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 100;
  pointer-events: none;
  margin-top: 155px;
  margin-left: 8px;

  @include breakpoint(large) {
    left: 15rem;
  }
  @include breakpoint(xlarge) {
    left: 18rem;
  }

}

