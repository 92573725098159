// --------------------------------------------------
// Module: Layer Groups Menu
// --------------------------------------------------

//
// Layer Group Container
// --------------------------------------------------
.layer-groups-container {
  border-bottom: 1px solid $silver;

  &:hover {
    background-color: rgba(107,113,123,.06);
  }

  &:not(.open) .layer-groups-container-content {
    display: none;
  }

  .fa-info-circle {
    color: $blue-dark;
  }

  .fa-exclamation-triangle {
    color: $orange;
  }
}

.layer-groups-container-title {
  font-weight: $global-weight-bold;
  padding: rem-calc(6);
  padding-right: 1em;
  position: relative;

  &:hover {
    background-color: rgba(107,113,123,.06);
    cursor: pointer;
  }

  &::after {
    @include css-triangle(0.4em, $primary-color, up);

    position: absolute;
    top: 50%;
    margin-top: -0.2em;
    right: rem-calc(6);
  }
  &.closed::after {
    transform: rotate(180deg);
  }
}


//
// Layer Group Toggle
// --------------------------------------------------
.layer-group-toggle {
  border-top: 1px solid $white;
  font-size: rem-calc(12);
}

.layer-group-toggle-header {

  &:hover {
    background-color: rgba(107,113,123,.06);
  }

  .cell:first-child:hover {
    cursor: pointer;
  }

  .layer-group-toggle-label {
    padding: rem-calc(6);
    display: block;
    padding-left: rem-calc(30); // switch's width (20) + margin (10)
  }

  .switch {
    margin: rem-calc(6) rem-calc(4) 0 rem-calc(6);

    &.tiny .switch-paddle {
      width: 1.25rem;
    }

    &.tiny .switch-paddle::after {
      width: 0.5rem;
    }

    &.tiny input:checked ~ .switch-paddle::after {
      left: 0.625rem;
    }
  }

  .layer-group-toggle-icons {
    padding: rem-calc(6);
  }
}


//
// Legends
// --------------------------------------------------
.legend-items {
  padding: 0 0 rem-calc(6);
}

.legend-item {
  font-size: rem-calc(12);
  padding: 0 rem-calc(6);
}

.legend-icon {
  width: 2.5em;
  height: 1.5em;
  display: inline-block;
  vertical-align: top;
  margin: 0 rem-calc(2) 0 0;
  position: relative;
  text-align: center;
}

.legend-icon-layer {
  display: inline-block;

  &.line + &.line {
    position: absolute;
    top: 0;
    left: 0;
  }
}

svg.legend-icon-layer {
  height: 1.5em;
  width: 2.5em;
}

.polygon-stacked {
  position: relative;

  .fa + .fa {
    position: absolute;
    top: -0.2em;
    left: 0.2em;
  }
}

.polygon-fill-dash {
  opacity: 0.5;
  display: inline-block;
  width: 0.8em;
  height: 0.8em;
  position: relative;

  .fill, .dash {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .fill {
    opacity: 0.3;
  }

  .dash {
    border-width: 2px;
    border-style: dotted;
  }
}
