// --------------------------------------------------
// Module: Ember Tooltips overrides
// --------------------------------------------------

.ember-tooltip,
.ember-popover {
  line-height: $global-lineheight;
  padding: $global-margin*0.75 $global-margin;
  border: 0;
  text-shadow: none;
}


//
// Tooltips
// --------------------------------------------------
.ember-tooltip {
  background: $charcoal;
  color: $white;
  font-size: rem-calc(12);
}
.ember-tooltip[x-placement^="top"] .ember-tooltip-arrow {
  border-top-color: $charcoal;
}
.ember-tooltip[x-placement^="right"] .ember-tooltip-arrow {
  border-right-color: $charcoal;
}
.ember-tooltip[x-placement^="bottom"] .ember-tooltip-arrow {
  border-bottom-color: $charcoal;
}
.ember-tooltip[x-placement^="left"] .ember-tooltip-arrow {
  border-left-color: $charcoal;
}


//
// Popovers
// --------------------------------------------------
.ember-popover {
  background: $white-smoke;
  color: $black;
  font-size: 1rem;
}
.ember-popover[x-placement^="top"] .ember-tooltip-arrow {
  border-top-color: $white-smoke;
}
.ember-popover[x-placement^="right"] .ember-tooltip-arrow {
  border-right-color: $white-smoke;
}
.ember-popover[x-placement^="bottom"] .ember-tooltip-arrow {
  border-bottom-color: $white-smoke;
}
.ember-popover[x-placement^="left"] .ember-tooltip-arrow {
  border-left-color: $white-smoke;
}


//
// Dark Popovers
// --------------------------------------------------
.ember-popover.dark {
  background: $charcoal;
  color: $white;

  a:not(.button) {
    color: $dcp-yellow;
  }
}
.ember-popover.dark[x-placement^="top"] .ember-tooltip-arrow {
  border-top-color: $charcoal;
}
.ember-popover.dark[x-placement^="right"] .ember-tooltip-arrow {
  border-right-color: $charcoal;
}
.ember-popover.dark[x-placement^="bottom"] .ember-tooltip-arrow {
  border-bottom-color: $charcoal;
}
.ember-popover.dark[x-placement^="left"] .ember-tooltip-arrow {
  border-left-color: $charcoal;
}


//
// White Popovers
// --------------------------------------------------
.ember-popover.white {
  background: $white;
}
.ember-popover.white[x-placement^="top"] .ember-tooltip-arrow {
  border-top-color: $white;
}
.ember-popover.white[x-placement^="right"] .ember-tooltip-arrow {
  border-right-color: $white;
}
.ember-popover.white[x-placement^="bottom"] .ember-tooltip-arrow {
  border-bottom-color: $white;
}
.ember-popover.white[x-placement^="left"] .ember-tooltip-arrow {
  border-left-color: $white;
}


//
// Popover padding
// --------------------------------------------------
.ember-popover.tiny-padding {
  padding: rem-calc(4);
  border-radius: 0;
}
