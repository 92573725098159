// --------------------------------------------------
// Module: BBL Lookup
// --------------------------------------------------

.bbl-lookup {
  margin: 0 rem-calc(6);
  position: relative;
  z-index: 1;
}

.bbl-lookup-toggle {
  height: 1.5rem;
  line-height: 1.5rem;
  padding-top: 0;
  padding-bottom: 0;
}

.bbl-lookup-form {
  position: absolute;
  top: 100%;
  right: rem-calc(-6);
  bottom: auto;
  left: rem-calc(-6);
  background-color: $off-white;
  padding: rem-calc(6);
  box-shadow: 0 4px 0 rgba(0,0,0,0.1);

  label {
    font-size: rem-calc(12);
    color: $dark-gray;
  }

  .bbl-power-select {
    margin: 0 0 rem-calc(3);
  }

  input[type="number"] {
    margin: 0 0 rem-calc(6);
  }

  .block-container {
    padding-right: rem-calc(3);
  }

  .lot-container {
    padding-left: rem-calc(3);
  }
}
