// --------------------------------------------------
// Module: Icons
// --------------------------------------------------

.icon {
  display: inline-block;
  vertical-align: middle;
  position: relative;

  &.tax-lot {
    width: 0.5em;
    height: 1.2em;
    transform: rotate(20deg);
    border: 0.2em solid $primary-color;
    border-radius: 0.1em;
    margin: -0.3em 0.8em 0 0.3em;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -0.2em;
      right: auto;
      bottom: -0.2em;
      left: auto;
      border: 0.1em solid $primary-color;
      width: 0.2em;
      border-radius: 0.1em;
    }
    &::before {
      right: 100%;
      margin-right: 0.3em;
      border-left-width: 0;
    }
    &::after {
      left: 100%;
      margin-left: 0.3em;
      border-right-width: 0;
    }
  }

  &.polygon {
    height: 1.1em;
    width: 0.6em;
    background-color: $primary-color;
    transform: skew(20deg,-10deg);
    margin: 0 0.6em 0 0.5em;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: auto;
      left: auto;
      background-color: inherit;
      border-radius: 0.1em;
    	height: 0.6em;
    	width: 1em;
      transform: skew(-25deg);
      transform-origin: 100% 0;
    }
  }

  &.distance {
    height: 0.1em;
    width: 0.7em;
    background-color: $primary-color;
    transform: rotate(-45deg);
    bottom: 0.1em;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: -0.15em;
      border: 0.2em solid transparent;
    }
    &::before {
      border-right: 0.3em solid $primary-color;
      right: 100%;
      margin-right: -0.1em;
    }
    &::after {
      border-left: 0.3em solid $primary-color;
      left: 100%;
      margin-left: -0.1em;
    }
  }
}

.search-results .fa:not(.fa-home) {
  font-size: rem-calc(16);
  margin-right: 0.5rem;
}

.bookmarks {

  .icon {
    font-size: 0.75rem;
  }

  .fa:not(.fa-bookmark):not(.fa-home) {
    font-size: rem-calc(16);
    margin-right: 0.5rem;
  }
}
