// --------------------------------------------------
// Base: Typography
// --------------------------------------------------

//
// Headings
// --------------------------------------------------
.header-xxlarge { @extend h1; }
.header-xlarge {  @extend h2; }
.header-large {   @extend h3; }
.header-medium {  @extend h4; }
.header-small {   @extend h5; }
.header-tiny {    @extend h6; }

h6,
.header-tiny {
  text-transform: uppercase;
  font-weight: $global-weight-normal;
  margin-bottom: 0.5em;
}


//
// Buttons
// --------------------------------------------------
button {
  cursor: pointer;
}

.button.gray {
  background-color: $white-smoke;
  color: darken($primary-color, 10%);

  &:hover {
    background-color: darken($white-smoke, 10%);
    color: darken($primary-color, 20%);
  }
}


//
// Typography helper classes
// --------------------------------------------------
.nowrap,
.no-wrap {
  white-space: nowrap;
}

.text-small {
  font-size: rem-calc(13);
  line-height: 1.7;
}

.text-tiny {
  font-size: rem-calc(10);
  line-height: 1.5;
}

.text-weight-normal {
  font-weight: $global-weight-normal;
}

.text-weight-bold {
  font-weight: $global-weight-bold;
}


//
// Margin helper classes
// --------------------------------------------------
.no-margin {
  margin: 0 !important;
}

$sides: top right bottom left;
@each $side in $sides {
  $i: index($sides, $side);

  .no-margin-#{$side} {
    margin-#{$side}: 0 !important;
  }

  .tiny-margin-#{$side} {
    margin-#{$side}: $global-margin*0.25 !important;
  }

  .small-margin-#{$side} {
    margin-#{$side}: $global-margin*0.5 !important;
  }

  .medium-margin-#{$side} {
    margin-#{$side}: $global-margin !important;
  }

  .large-margin-#{$side} {
    margin-#{$side}: $global-margin*2 !important;
  }
}


//
// Inputs
// --------------------------------------------------
::-ms-clear {
  // nix IE's default clear buttons
  display: none;
}
